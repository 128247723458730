.MuiDataGrid-root {
  cursor: pointer !important;
}
.MuiDataGrid-root .MuiDataGrid-cell{
  border-bottom: none !important;
}

/*  Style for Search Box*/
.MuiPaper-root.MuiPaper-elevation1.search {
  box-shadow: none !important;
  border: 1px solid #C8C8C8 !important;
}

/*remove footer div*/
.MuiDataGrid-root .MuiDataGrid-footerContainer{
  display: none !important
}
@media screen and (orientation:portrait) {
  #main-page{
    display: none;
  }
  .tabletmodepatientlist{
    max-width: 100%;
    display:table;
    margin: -10vh -50vh -3vh -29vh !important;
    height: auto !important;
    width:max-content;
  }
}
