* {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
}

/*  Style for Search Box*/
.MuiPaper-root.MuiPaper-elevation1 {
  box-shadow: none !important;
  border: none !important;
}


/*for placeholder of textfield in recommendation*/
textarea.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputMultiline.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
  margin-top: -25rem;
}
@media screen and (max-width: 1450px) {
  textarea.MuiOutlinedInput-input.MuiInputBase-input.MuiInputBase-inputMultiline.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input{
   margin-top: -19rem;
  }
}

/*search box label position*/
.MuiInputBase-input.search{
  padding: 8px 0 7px !important;
  font-family: "Roboto, sans-serif !important";
}

/*this is for coverage map brushing session title*/
@media screen and (min-width: 1450px){
.css-wvmkh5-MuiTypography-root.brushsession{
  padding: 1.5rem 1rem 0 1.5rem !important;
}
}

/*color change for the selected row in datagrid*/
.MuiDataGrid-row.Mui-selected{
  background-color: #E5E5E5 !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  position: absolute;
  /* background: #FFFFFF; */
}

@media screen and (orientation:portrait) {
  #patientdetails{
    display: none;
  }
  .tabletmodedetails{
    max-width: 100%;
    display:table;
    height: auto !important;
    width:100%;
  }
}

@media screen and (orientation:landscape) {
  .tabletmodedetails{
    display: none;
  }
  #patientdetails{
    display: block;
  }
}
.MuiCircularProgress-root{
  color:#4cbac1 !important
}