.filter-popup {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    padding-left: 15px;
    padding-bottom: 50px;
    background-color: white;
    display: flex;
    direction: row;
    border: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.08),
    0px 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 235px;
    height: 72px;
}

.duration-filter-popup {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    padding-left: 15px;
    padding-bottom: 50px;
    background-color: white;
    display: flex;
    direction: row;
    border: none;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.08),
    0px 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    width: 400px;
    height: 100px;
}

.filter-popup .MuiFormControl-root {
    margin: 20px 0;
}

.filter-popup input.MuiOutlinedInput {
    padding: 20px !important;
}

.inputRounded .MuiOutlinedInput-root {
    border-radius: 40px;
    height: 40px;
    border-color: red;
}

@media screen and (max-width: 1450px) {
    .inputRounded .MuiOutlinedInput-root {
        border-radius: 40px;
        height: 35px;
        font-size: 12px;
    }
}

.seprator {
    width: 15px;
    height: 2px;
    margin-top: 45px;
    margin-left: 16px;
    margin-right: 16px;
}

.percent {
    width: 13px;
    height: 19px;
    margin-top: 35px;
    margin-left: 5px;
}

.time-seprator {
    width: 4px;
    height: 15px;
    margin-top: 16px;
    margin-left: 8px;
    margin-right: 8px;
}

.MuiDataGrid-root .MuiDataGrid-footerContainer {
    display: flex !important;
}

.jaw-color input {
    margin-right: 5px;
}

.coverage-map-no-data-card {
    height: 100%;
    margin-top: 2rem;
}

.coverage-map-no-data-text {
    padding: 10px;
    text-align: center;
    width: 100%;
}
