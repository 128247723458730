.centered-form {
  margin: auto;
  width: 33%;
  display: flex;
  flex-direction: column;
}

.centered-form a {
  display: block;
  margin: 5px 0;
  text-align: center;
}
