/* for making the signup form scrollable*/
.login-signup-form.gigya-style-modern.gigya-mac.gigya-chrome {
  display: flex;
  height: 100%;
  width: 100%;
}
.login-signup-form.gigya-style-modern.gigya-windows.gigya-chrome {
  display: flex;
  height: 100%;
  width: 100%;
}

@media screen and (max-width:1600px) {
  .login-signup-form.gigya-style-modern.gigya-mac.gigya-chrome {
    display: flex;
    height: 100%;
    width: 58%;
  }
  .login-signup-form.gigya-style-modern.gigya-windows.gigya-chrome {
    display: flex;
    height: 100%;
    width: 58%;
  }
}

@media screen and (orientation:portrait) {
  .overall-homepage{
    display: none;
  }
  .tabletmode{
    max-width: 100%;
    display:table;
    height: auto !important;
    width:max-content;
  }
}

@media screen and (orientation:landscape) {
  .tabletmode{
    display: none;
  }
  .overall-homepage{
    display: block;
    background-color: white;
  }
}