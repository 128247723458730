/* Resets */
p {
  margin: 0;
}

/* 
Styles below pulled from 
https://xd.adobe.com/view/f3dabf2b-8f3f-44e9-846f-ecc90910d3f9-a689/
CPD Design Guidelines
*/
:root {
  /* Colors: */
  --color-007653: #007653;
  --color-d2010d: #d2010d;
  --color-de4d55: #de4d55;
  --color-ed999d: #ed999d;
  --color-f7d9da: #f7d9da;
  --color-ffffff: #ffffff;
  --color-219ca6: #219ca6;
  --color-4cbac1: #4cbac1;
  --color-99d7db: #99d7db;
  --color-d9f0f2: #d9f0f2;
  --color-8f1f1d: #8f1f1d;
  --color-b06161: #b06161;
  --color-d1a4a4: #d1a4a4;
  --color-eddddd: #eddddd;
  --color-f86905: #f86905;
  --color-fa9650: #fa9650;
  --color-fcc39a: #fcc39a;
  --color-fee7d9: #fee7d9;
  --color-16545a: #16545a;
  --color-1c838a: #1c838a;
  --color-ea5e00: #ea5e00;
  --color-333333: #333333;
  --color-585554: #585554;
  --color-949391: #949391;
  --color-d6d6d6: #d6d6d6;
  --color-f3f3f3: #f3f3f3;
  --color-d1010c: #d1010c;
  --color-444141: #444141;
  /* Font/text values */
  --font-family-colgate-ready: Colgate Ready;
  --font-family-open-sans: Open Sans;
  --font-style-italic: italic;
  --font-style-normal: normal;
  --font-weight-600: 600px;
  --font-weight-900: 900px;
  --font-weight-bold: bold;
  --font-weight-normal: normal;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-26: 26px;
  --font-size-34: 34px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --character-spacing-0: 0px;
  --character-spacing--0-54: -0.54px;
  --character-spacing--0-8: -0.8px;
  --character-spacing--0-96: -0.96px;
  --character-spacing--1-12: -1.12px;
  --character-spacing--1-28: -1.28px;
  --character-spacing--0-26: -0.26px;
  --line-spacing-16: 16px;
  --line-spacing-20: 20px;
  --line-spacing-24: 24px;
  --line-spacing-28: 28px;
  --line-spacing-32: 32px;
  --line-spacing-42: 42px;
  --line-spacing-44: 44px;
  --line-spacing-56: 56px;
  --line-spacing-64: 64px;
  --line-spacing-72: 72px;
}

/* Character Styles */
.title-1 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-64);
  line-height: var(--line-spacing-72);
  letter-spacing: var(--character-spacing--1-28);
  color: var(--color-d1010c);
}

.title-2 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-56);
  line-height: var(--line-spacing-64);
  letter-spacing: var(--character-spacing--1-12);
  color: var(--color-d1010c);
}

.title-3 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-48);
  line-height: var(--line-spacing-56);
  letter-spacing: var(--character-spacing--0-96);
  color: var(--color-d1010c);
}

.title-4 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-40);
  line-height: var(--line-spacing-44);
  letter-spacing: var(--character-spacing--0-8);
  color: var(--color-d2010d);
}

.title-5 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-900);
  font-size: var(--font-size-34);
  line-height: var(--line-spacing-42);
  letter-spacing: var(--character-spacing--0-54);
  color: var(--color-d2010d);
}

.title-6 {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-spacing-32);
  letter-spacing: var(--character-spacing--0-26);
  color: var(--color-d2010d);
}

.body-1-semi-bold {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-20);
  line-height: var(--line-spacing-28);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-444141);
}

.body-1-regular {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-20);
  line-height: var(--line-spacing-28);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.body-2-semi-bold {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.body-2-regular {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.body-3-semi-bold {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.body-3-regular {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-20);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.button-regular {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-d2010d);
}

.button-small {
  font-family: var(--font-family-colgate-ready);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-24);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-d2010d);
}

.caption-14pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-16);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

.caption-12pt {
  font-family: var(--font-family-open-sans);
  font-style: var(--font-style-italic);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-12);
  line-height: var(--line-spacing-16);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-333333);
}

/* App Styles */

footer {
  background-color: #811c1a !important;
  color: var(--color-ffffff);
  display: flex;
  padding: 15px 5px;
  justify-content: space-between;
}

footer .links {
  display: flex;
}

footer .links > * {
  margin: 0 5px;
}

footer .links a {
  color: #fff;
  font-weight: inherit;
}

.gigya-screen.portrait {
  padding-left: 130px !important;
  padding-right: 180px !important;
  padding-top: 0px !important;
  margin-right: 10% !important;
}



/*This is for Forgot Password style in Login Page*/
a.gigya-composite-control.gigya-composite-control-link{
  color: #D2010D !important;
  text-decoration: underline !important;
  font-family: "Roboto", sans-serif !important ;
  padding-top: 2rem !important;
  margin-bottom: 0rem !important;
}

a.gigya-composite-control.gigya-composite-control-link.signuplink{
  text-decoration: none !important;
  padding-top: 0rem !important;
}

/* autocomplete box design */
.css-18w7bic .MuiOutlinedInput-root{
  border-radius: 40px !important;
  border:  "1px solid #C8C8C8 !important";
  height: 2rem !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root{
  border-radius: 40px !important;
  border:  "1px solid #C8C8C8 !important";
  height: 2rem !important;
}

/*datagrid in coverage page bodyborder remove*/
.MuiDataGrid-root-181{
  border: none !important;
}

/* coverage page filter textinput position */
.css-1kpdewa-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  margin-top: -0.7rem !important;
}
.css-18w7bic .MuiAutocomplete-input{
  margin-top: -0.7rem !important;
}

@media screen and (max-width: 1450px) {
label{
  font-size:  16px !important;
}
}

/*for login page */
@media only screen and (min-width: 1290px) {
  div#Pro_Portal_Login{
  margin-top: 0vh;
  margin-left: 2vw;
  margin-right: 3vw;
}
}


/*for signup page */
.gigya-screen{
  height: 98% !important;
}
@media screen and (max-width: 1450px) {
  .gigya-screen{
    height: 135% !important;
  }
}

/*width for signup page*/
.gigya-screen-content div.gigya-screen.landscape{
  width: 100% !important;
  overflow-x: clip !important;
}

.gigya-screen-caption{
  display: none !important;
}
.gigya-screen.portrait{
  padding-top: 9% !important;
  width: 81% !important;

}

@media screen and (max-width: 1420px) {
  .login-signup-form{
    position: absolute;
    zoom: 75%;
  }
}

@media screen and (min-width: 1430px) and (max-width: 1600px){
  .login-signup-form{
    position: absolute;
    zoom: 80%;
  }
}

div#Pro_Portal_ProfileUpdate{
  height: 100%;
  width: 100%;
}

/* this is for disabling the tooltip msg*/
.gigya-password-strength-bubble .gig-balloon-frame{
  margin-top: -18%;
  margin-left: -14%;
  width: 40% !important;
}
.gigya-password-strength-bubble .gig-balloon-nub-up{
  display: none;
}
@media screen and (min-width: 1450px) {
  .gigya-password-strength-bubble .gig-balloon-frame{
    margin-top: 4%;
    margin-left: 40%;
    width: 40% !important;
  }
}