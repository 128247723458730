.nav-bar {
  display: flex;
  flex-direction: column;
  height: 30px;
  color: #e2e6eb;
}
@media screen and (orientation: landscape) and (min-width: 993px) and (max-width: 1366px) {
  .nav-bar {
    display: block!important;
  }
}
@media screen and (orientation:portrait) {
  .nav-bar {
    display: none;
    height: 30px !important;
  }
}
/* User created styles */

html,
body {
  color: #333333;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  background-color: #F4F5F6;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

html > *,
body > * {
  height: 100%;
  width: 100%;
}

body {
  background-color: #e5e5e5;
}

.app-title {
  font-size: 2em;
  margin-left: 5px;
}

a {
  text-decoration: none;
}

main {
  flex: 1;
  overflow-y: auto;
}

.header-logo-link {
  display: flex;
  align-items: center;
}

.header-logo {
  width: 165px;
  height: 64.15px;
  margin-top: 2px;
  margin-left: 3px;
}
@media screen and (max-width: 1450px) {
  .header-logo {width: 124px;
  height: 44.15px;
  margin-top: 2px;
  margin-left: 3px;
}
}
.red-font {
  color: #090a0c;
}
.rounded-button {
  border-radius: 30px !important;
}

.bold-font {
  font-weight: bold;
}

.header-logo-and-nav {
  display: flex;
  align-items: center;
  color: #e2e6eb;
}

.header-main-menu {
  display: flex;
}

.header-main-menu a.active {
  color: #e23023;
  font-weight: bold;
  border-bottom: 5px solid #e23023;
}
 
/* for scrollbar */
::-webkit-scrollbar{
  width: 8px;
}

::-webkit-scrollbar-track{
  background: 'white';
}

::-webkit-scrollbar-thumb{
  background: #818282;
  border-radius: 40px;
}


/*for removing datagrid lines between each rows*/
.MuiDataGrid-root .MuiDataGrid-cell{
  border-bottom: none !important;
  border: none !important
}
.MuiDataGrid-root{
  border: none !important;
}
@media only screen and (max-width: 1365px) {
  .MuiDataGrid-columnHeader{
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: normal;
    line-height: 25px;
    color: #525252 !important;
  }
}
@media only screen and (min-width: 1365px) {
.MuiDataGrid-columnHeader{
 
  font-size: 1.125rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 25px;
  color: #525252 !important;
}
}

.MuiDataGrid-cell{
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  line-height: 20px;
  color: #2d2d2d !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
  padding: 0px !important;
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-7.MuiGrid-grid-md-5 {
  height: 82vh !important;
  padding-bottom: 4rem;
}

/* textfield height increase*/

.input.MuiOutlinedInput-input.MuiInputBase-input.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  height: "2.1876rem !important"
}
