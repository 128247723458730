.portal-form input {
  display: block;
  width: 100%;
  margin-top: 5px;
  border-radius: 4.5px;
  border: 1px solid #b6bdc5;
  background-color: #fff;
  box-sizing: border-box;
  height: 43px;
}

.portal-form select {
  display: block;
  width: 100%;
  margin-top: 5px;
  border-radius: 4.5px;
  border: 1px solid #b6bdc5;
  background-color: #fff;
  box-sizing: border-box;
  height: 43px;
}

.portal-form label {
  font-weight: bold;
  margin: 10px 0;
  display: block;
}

.portal-form input[type="submit"] {
  background-color: #da291b;
  padding: 10px;
  font-weight: bold;
  height: 43px;
  border-radius: 1.8px;
  box-shadow: 0 0 1px 0 rgb(0 0 0 / 40%);
  color: #fff;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: center;
  font-size: 19px;
  border: none #1371b9;
}

.portal-form a {
  display: block;
  margin: 5px 0;
  text-align: center;
}
